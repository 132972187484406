@import '../../style';

.info-tiles {
  margin: 1rem 0;
}
.info-tiles .subtitle {
  font-weight: 300;
  color: #8f99a3;
}

.circle-status-tag {
  height: 1rem !important;
  width: 1rem !important;
  padding: 0 !important;
}

.status-info-columns {
  margin-bottom: 00.75rem !important;
}

.box.is-fullwidth {
  width: 100%;
}

.info-box {
  padding: 0.5rem 1.25rem !important;
}

.is-sorted-index {
  color: $pink !important;
}

.button-is-bold {
  font-weight: 700 !important;
}

.has-margin-auto-0 {
  margin: auto 0;
}

.has-border-success {
  box-shadow: inset 0 0 0 3px $success;
}

.has-overall-info {
  background-color: white;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 25%,
    rgba(0, 209, 178, 0.25) 100%
  );
}

.card {
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  box-shadow: none;
  .card-header {
    box-shadow: none;
  }
}

