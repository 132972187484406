@import '../../style';

nav.navbar {
  border-top: 4px solid $purple;
  margin-bottom: 1rem;
}

div.navbar-start {
  margin-left: auto;
  margin-right: 1rem;
}

div.navbar-brand {
  margin-left: 0 !important;
}

.navbar-item.brand-text {
  font-weight: 300;
}
.navbar-item,
.navbar-link {
  font-size: 14px;
  font-weight: 700;
}

#navMenu {
  transition: 0.3s all ease-in-out;
}
