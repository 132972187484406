.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 999;
}
    .modal-main {
      position:fixed;
      background: white;
      width: 100%;
      max-width: 800px;
      height: 200px;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
    .modal-main img {
      width: 100%;
    }
    .modal-main p {
        text-align: center;
        margin-top: 10%;
    }
.closeButton {
    border: none;
    background: transparent;
    font-weight: bold;
    padding: 15px 20px;
    color: #000000;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 20px;
}

  
  
  .display-block {
    display: block;
  }
  
  .display-none {
    display: none;
  }
  