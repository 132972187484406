@import '../../style';

.columns {
  width: 100%;
  height: 100%;
  margin-left: 0;
}

.bottom-padding {
  padding-bottom: 50px;
}
