html,
body {
  font-size: 16px;
  line-height: 1.5;
  background: #ecf0f3;
}

.is-clickable {
  cursor: pointer;
}
